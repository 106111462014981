import React from "react"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import { Bar } from 'react-chartjs-2'
import SEO from "../../components/seo"

const TaxPage = ({data}) => {
    let rates = data.graphAPI.taxRates
    let meta = data.graphAPI.contentTaxRate.meta
    let content = data.graphAPI.contentTaxRate.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="title-section">
                <h1 className="title is-size-1">{meta.title}</h1>
            </div>
            <Content content={content} />
            <div className="content-align">
            <div className="table-container">
            <table className="table" style={{marginTop: '3rem'}}>
                <thead>
                    <tr>
                        <td>Year</td>
                        <td>Debt Service</td>
                        <td>Maintenance</td>
                        <td>Total Tax Rate</td>
                    </tr>
                </thead>
                <tbody>
                    {rates.map(rate => (
                        <tr key={rate.id}>
                            <td>{rate.year}</td>
                            <td>{(rate.interestSinkingRate).toFixed(4)}</td>
                            <td>{(rate.maintenanceOperationsRate).toFixed(4)}</td>
                            <td>{(rate.interestSinkingRate+rate.maintenanceOperationsRate+rate.roadRate).toFixed(4)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            </div>
        </Layout>
    )
}

export default TaxPage

export const query = graphql`
    query taxPageById {
        graphAPI {
            contentTaxRate {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            taxRates(sort: "year:desc") {
                year
                maintenanceOperationsRate
                interestSinkingRate
                roadRate
                contractRate
                orderLevyingTaxes {
                    ...publicDocument
                }
            }
        } 
    }
`


